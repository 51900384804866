import type { SurveyLogoType } from '@views/builder/types/Builder'
import type { SurveyFormState } from '@views/survey-form/typings/SurveyForm'

import type { AppLayoutType } from '@typings/AppLayout'
import type { FraudCheckId, FraudDetectionStatus, RealTimeFraudCheckId } from '@typings/Fraud'
import type { InterviewParticipantType } from '@typings/Interviews'
import type { CalendarParticipantType } from '@typings/Interviews'
import type { PaymentMethodType, PaymentStatus, PaymentStatusType } from '@typings/Payment'
import type {
  Currency,
  DefinitionType,
  IncompleteStates,
  ProjectType,
  ResponseFlag,
  SurveyPlatform,
  SurveyProvider,
  SurveyStatus,
} from '@typings/Survey'
import type { UserColumnSettingsTableType } from '@typings/Users'
import type { WorkflowFlag, WorkflowStateType } from '@typings/Workflows'

export const DATE_FORMAT = 'DD/MM/YYYY'

export const DATE_HOUR_FORMAT = 'DD/MM/YYYY (HH:mm)'

export const DEFAULT_REFERRER_PERCENTAGE = 0.2

export const DEFAULT_CURRENCY: Currency = 'GBP'

export const DEFAULT_SURVEY_LANGUAGE = 'EN-GB'

export const DEFAULT_SURVEY_PLATFORM: SurveyPlatform = 'qrowdsy'

export const MAX_AWS_FILE_SIZE_UPLOAD_IN_BYTES = 1024 * 1024 * 200 // 200 MB

export type Constant<T extends string> = Record<T, T>
type ProjectTypeWithoutNotUsed = Exclude<ProjectType, 'NOT_USED'>

export const PROJECT_TYPE: Constant<ProjectTypeWithoutNotUsed> = {
  SURVEY: 'SURVEY',
  INTERVIEW: 'INTERVIEW',
}

export const SURVEY_PROVIDERS: Constant<SurveyProvider> = {
  QUALTRICS: 'QUALTRICS',
  SURVEYJS: 'SURVEYJS',
}

export const WORKFLOW_STATE_TYPES: Constant<WorkflowStateType> = {
  ON_HOLD: 'ON_HOLD',
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  ACCEPTED: 'ACCEPTED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
}

export const DISQUALIFIED_WORKFLOW_STATE = 'DISQUALIFIED'

export const WORKFLOW_STATE_FLAGS: Constant<WorkflowFlag> = {
  REDIRECTED_TO_EXTERNAL: 'REDIRECTED_TO_EXTERNAL',
  EMAIL_REJECTION: 'EMAIL_REJECTION',
  SCHEDULE_INTERVIEW: 'SCHEDULE_INTERVIEW',
  UPDATE_QUOTA: 'UPDATE_QUOTA',
}

export const R_FLAG: Constant<ResponseFlag> = {
  REFERRAL_SENT: 'REFERRAL_SENT',
}

export const P_STATUS: Constant<PaymentStatus> = {
  PAID: 'PAID',
  NOT_INITIATED: 'NOT_INITIATED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  NOT_PAID: 'NOT_PAID',
}

export const P_STATUS_TYPE: Constant<PaymentStatusType> = {
  RESPONSE: 'RESPONSE',
  REFERRAL: 'REFERRAL',
}

export const S_STATUS: Constant<SurveyStatus> = {
  ARCHIVED: 'ARCHIVED',
  CLOSED: 'CLOSED',
  IN_PREPARATION: 'IN_PREPARATION',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
}

export const F_STATUS: Constant<FraudDetectionStatus> = {
  FAILED: 'FAILED',
  PASSED: 'PASSED',
  SKIPPED: 'SKIPPED',
}

export const ACTIVE_SURVEY_STATUSES = [
  S_STATUS.IN_PREPARATION,
  S_STATUS.IN_PROGRESS,
  S_STATUS.PAUSED,
]

export const NON_ARCHIVED_PROJECT_STATUSES = [
  S_STATUS.CLOSED,
  S_STATUS.IN_PREPARATION,
  S_STATUS.IN_PROGRESS,
  S_STATUS.PAUSED,
]

export const CONFIG_IDS = {
  HAS_REDIRECT: 'hasRedirect',
}

export const LOGO_MAX_SIZE = 2_000_000

export const FRAUD_CHECKS: Constant<FraudCheckId> = {
  IP_CONNECTIONS: 'IP_CONNECTIONS',
  SPELLCHECK: 'SPELLCHECK',
  VPN: 'VPN',
  REPEATED_ANSWERS: 'REPEATED_ANSWERS',
  DEFAULT_RANK: 'DEFAULT_RANK',
  EMAIL_CONNECTIONS: 'EMAIL_CONNECTIONS',
  LOCATION: 'LOCATION',
  REPEATED_TEXT: 'REPEATED_TEXT',
  TOO_FAST: 'TOO_FAST',
  SHORT_TEXT: 'SHORT_TEXT',
}

export const REAL_TIME_FRAUD_CHECK: Constant<RealTimeFraudCheckId> = {
  COUNTRY_CHECK: 'COUNTRY_CHECK',
  EMAIL_CONNECTIONS_CHECK: 'EMAIL_CONNECTIONS_CHECK',
  IP_CONNECTIONS_CHECK: 'IP_CONNECTIONS_CHECK',
  MC_CHECK: 'MC_CHECK',
  SPELLCHECK: 'SPELLCHECK',
  VPN_CHECK: 'VPN_CHECK',
}

export const SURVEY_STATE: Constant<SurveyFormState> = {
  ALREADY_COMPLETED: 'ALREADY_COMPLETED',
  COMPLETED: 'COMPLETED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
  DISABLED: 'DISABLED',
  DISQUALIFIED: 'DISQUALIFIED',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  REDIRECTING: 'REDIRECTING',
  LOADING: 'LOADING',
  SCHEDULING: 'SCHEDULING',
}

export const DEFAULT_TIMEZONE = 'UTC'

export const ELEMENTS_PER_PAGE_AVAILABILITY_CALENDAR = 4

export const PAYMENT_METHOD_TYPE: Constant<PaymentMethodType> = {
  TREMENDOUS: 'TREMENDOUS',
}

export const INTERVIEW_PARTICIPANT_TYPE: Constant<InterviewParticipantType> = {
  HOST: 'HOST',
  CANDIDATE: 'CANDIDATE',
  OBSERVER: 'OBSERVER',
}

export const CALENDAR_PARTICIPANT_TYPE: Constant<CalendarParticipantType> = {
  HOST: 'HOST',
  OBSERVER: 'OBSERVER',
}

export const APP_LAYOUT_TYPE: Constant<AppLayoutType> = {
  SURVEY_FORM: 'SURVEY_FORM',
  INTERVIEW: 'INTERVIEW',
  ACCESS_DENIED: 'ACCESS_DENIED',
}

export const SURVEY_LOGO_TYPE: Constant<SurveyLogoType> = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
}

export const TABLE_COLUMN_TYPE: Constant<UserColumnSettingsTableType> = {
  PROJECTS: 'PROJECTS',
  USERS: 'USERS',
  RESPONSES: 'RESPONSES',
}

export const INCOMPLETE_STATES: Record<string, IncompleteStates> = {
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_STARTED: 'INCOMPLETE-STARTED',
  INCOMPLETE_NOT_STARTED: 'INCOMPLETE-NOT-STARTED',
}

export const DEFINITION_TYPE: Constant<DefinitionType> = {
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION',
}
