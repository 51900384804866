'use client'

import { createContext } from 'react'

import { AbilityClass, PureAbility } from '@casl/ability'
import { useAbility } from '@casl/react'

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'
export type Subjects =
  | 'Analytics'
  | 'Calendar'
  | 'CSV'
  | 'Dashboard'
  | 'Definition'
  | 'Email'
  | 'Emails'
  | 'EmailTemplate'
  | 'Folder'
  | 'Interview'
  | 'Link'
  | 'Organisation'
  | 'Payment'
  | 'PaymentMethod'
  | 'PII'
  | 'Project'
  | 'Projects'
  | 'ProjectStatus'
  | 'Quota'
  | 'Response'
  | 'Responses'
  | 'Reward'
  | 'Users'
  | 'Timeslot'
  | 'Workflow'
  | 'all'

type CheckAbilityFunction = (action: Actions, subject: Subjects) => boolean

export type Can = CheckAbilityFunction
export type Cannot = CheckAbilityFunction

type AppAbility = PureAbility<[Actions, Subjects]>

export const appAbility = PureAbility as AbilityClass<AppAbility>

export const AbilityContext = createContext<AppAbility>(null as unknown as AppAbility)

export const usePermissions = () => useAbility(AbilityContext)
