import VerifiedBadge from '@components/VerifiedBadge'

type Props = {
  email: string
  firstName: string | null
  lastName: string | null
  isVerified?: boolean
}

export default function UserEmailColumn({ email, firstName, isVerified, lastName }: Props) {
  return (
    <div className='flex items-end gap-x-2'>
      <div>
        <span className='block text-xs text-gray-500'>
          {firstName && `${firstName} `}
          {lastName && lastName}
        </span>

        {email}
      </div>
      {isVerified && <VerifiedBadge />}
    </div>
  )
}
